import React from "react";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";

export default function Homepage() {
  return (
    <div>
      <Container className="text-center" fluid>
        <Jumbotron>
          <h1>xanndev.com</h1>
          <p>Website coming soon...</p>
          <p className="text-small text-center">
            In case of any concerns kindly{" "}
            <a href="mailto:azan.momin@gmail.com">email</a> us.
          </p>
        </Jumbotron>
      </Container>
    </div>
  );
}
