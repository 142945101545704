import React from "react";
import Homepage from "./Homepage.js";

export default function App() {
  return (
    <div>
      <Homepage />
    </div>
  );
}
